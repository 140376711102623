import { render, staticRenderFns } from "./depositForm.vue?vue&type=template&id=4a64c1fe&scoped=true"
import script from "./depositForm.vue?vue&type=script&lang=js"
export * from "./depositForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a64c1fe",
  null
  
)

export default component.exports