/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-16 16:27:15
 * @Module: 预存款表单
 */
<template>
  <div>
    <el-dialog :title="type=='add'?'添加预存款':type=='revision'?'修改预存款':''" :visible.sync="dialogVisible" width="40%" @closed="closed">
      <div style="padding:20px;">
        <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="金额" prop="amount" :rules="{ required: true, message: '金额不能为空'}">
                <el-input v-model="form.amount" oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入金额">
                  <el-select v-model="amounttype" slot="prepend" placeholder="请选择" style="width:60px;">
                    <el-option label="加" value="">
                    </el-option>
                    <el-option label="减" value="-"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="余额" prop="balance" :rules="{ required: true, message: '余额不能为空'}">
                <el-input v-model="form.balance" oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入余额"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="日期" prop="date" :rules="{ required: true, message: '日期不能为空'}">
                <el-date-picker style="width:100%;" value-format="timestamp" v-model="form.date" type="date" placeholder="选择日期" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注" prop="note">
                <el-input v-model="form.note" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请输入备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submit" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 <script>

export default {
  components: {},
  data () {
    return {
      type: 'add',
      dialogVisible: false,
      form: {
        amount: null,
        balance: null,
        date: null,
        note: null
      },
      amounttype: ''
    };
  },

  mounted () {
  },
  methods: {
    show ({ type = 'add', data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      }
      this.type = type
      this.dialogVisible = true
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postDeposit()
        } else {
          return false
        }
      })
    },
    closed () {
      this.form = {
        amount: 0,
        balance: 0,
        date: null,
        note: null
      }
      this.amounttype = ''
    },
    // 添加应收款项
    async postDeposit () {
      const api = {
        revision: {
          func: this.$api.putChangeDeposit,
          params: {
            ...this.form,
            amount: this.amounttype + this.form.amount
          }
        },
        add: {
          func: this.$api.postDeposit,
          params: {
            ...this.form,
            companyUuid: this.$route.params.uuid,
            amount: this.amounttype + this.form.amount
          }
        }
      }
      const { status, data, info } = await api[this.type].func(api[this.type].params)

      // const { status, info } = await this.$api.postReceivable({ ...this.form, companyUuid: this.$route.params.uuid })
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>